<template>
  <div class="train_list" v-loading="loading" element-loading-text="拼命加载中">
    <header>
      <ul class="tab">
        <li class="tabs flex" v-show="location !== '陕西省'">
          <div class="radios relative">
            <div class="color66 span_name">
              <pre>地       区：</pre>
            </div>
            <selectCity marginLeft="90px" @getVal="setCityValue" :isLocation="false" style="width: 1200px" v-show="location == ''"></selectCity>
            <provinceCity class="provinceCity" @getVal="setCityValue" marginLeft="90px"
                          style="width: 1200px" v-show="location == '湖南省'"></provinceCity>
          </div>
        </li>
        <li class="tabs flex">
          <div class="radios relative">
            <div class="color66 span_name">培训类别：</div>
            <el-radio-group v-model="form.trainType" class="world">
              <ul class="top flex-wrap">
                <li class="">
                  <el-radio label="">全部</el-radio>
                </li>
                <li v-for="(n,i) in trainTab" :key="i" v-if="i < 9">
                  <el-radio :label="n.value">{{ n.label }}</el-radio>
                </li>
              </ul>
            </el-radio-group>
          </div>
        </li>
        <li class="tabs flex">
          <div class="radios relative">
            <div class="color66 span_name">残疾类别：</div>
            <el-radio-group v-model="form.disType" class="world">
              <ul class="top flex">
                <li class="">
                  <el-radio label="">全部</el-radio>
                </li>
                <li v-for="(n,i) in defect" :key="i">
                  <el-radio :label="n.value">{{ n.label }}</el-radio>
                </li>
              </ul>
            </el-radio-group>
          </div>
        </li>
        <li class="tabs flex">
          <div class="radios relative ">
            <div class="color66 span_name">
              <pre>时       间：</pre>
            </div>
            <el-date-picker
                v-model="form.appplyBeginTime"
                type="date"
                value-format="yyyy/MM/dd"
                class="w200"
                style="margin-left: 90px;"
                size="mini"
                popper-class="eagle-a11y-uncut"
                placeholder="请选择您的开始时间">
            </el-date-picker>
            <span class="paddL10 paddR10">至</span>
            <el-date-picker
                v-model="form.trainEndTime"
                type="date"
                value-format="yyyy/MM/dd"
                class="w200"
                size="mini"
                popper-class="eagle-a11y-uncut"
                placeholder="请选择您的结束时间">
            </el-date-picker>
          </div>
        </li>
      </ul>
    </header>
    <section>
      <div class="breadcrumb">
        <span class="name">当前位置：</span>
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
          <el-breadcrumb-item :to="{ path: '/training/' }">职业培训</el-breadcrumb-item>
          <el-breadcrumb-item>免费培训</el-breadcrumb-item>
        </el-breadcrumb>
      </div>

      <ul class="list flex flex-wrap">
        <li v-for="(n,i) in trainList" :key="i" class="box">
          <router-link :to="'./trainDetail?id='+n.id" target="_blank" :title="n.trainName" class="name">
            <div class="top relative">
              <img :src="n.picurl" alt="" class="banner err_image" :title="n.trainName">
            </div>
            <img src="@/static/train/registration.png" alt="" class="status" v-if="n.ing == 2">
            <img src="@/static/train/over.png" alt="" class="status" v-if="n.ing == 3">
            <img src="@/static/train/registered.png" alt="" class="status" v-if="n.ing == 4">
            <div class="bottom">
              <div class="time">
                {{ n.appplyBeginTime + '-' + n.appplyEndTime }}
              </div>
              <img src="@/static/select.png" alt="" class="img inline_block" v-if="n.isTui == '是'">
              <span class="b_title">{{ n.trainName }}</span>
              <div class="address">
                <ul>
                  <li class="line_clamp1"><span v-for="(item,index) in JSON.parse(n.disType)"
                                                :key="index" class="paddR5">{{ item }}</span></li>
                  <!-- <li>{{n.maxNumber}}人报名</li> -->
                </ul>
                <span class="line_clamp1"><i class="icon el-icon-location"></i> {{ n.trainAddress }}</span>
              </div>
            </div>
          </router-link>
        </li>
      </ul>
    </section>
    <div class="pages">
      <el-pagination
          background
          class="work_pagination"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page.sync="form.pageNum"
          :page-size="form.pageSize"
          prev-text="上一页"
          next-text="下一页"
          layout="prev, pager, next, total, jumper"
          :total="form.total">
      </el-pagination>
    </div>
  </div>
</template>

<script>
import selectCity from "@/components/search/selectCity";
import provinceCity from "@/components/search/provinceCity";

export default {
  name: "trainList",
  components: {
    selectCity,
    provinceCity
  },
  data() {
    return {
      trainList: [],
      form: {
        trainType: '',
        disType: '',
        appplyBeginTime: '',
        trainEndTime: '',
        trainC: '',
        trainT: '',
        total: 0,
        pageNum: 1,
        pageSize: 12,
        trainP: '',
      },
      trainTab: [],
      defect: [],
      loading: false,
      location: '',
    }
  },
  watch: {
    form: {
      handler(newName, oldName) {
        this.train();
      },
      deep: true
    }
  },
  methods: {
    async train() {
      this.loading = true;
      let res = await this.$api.trainListApi(this.form);
      try {
        this.loading = false;
        this.trainList = res.data.data.records;
        this.form.total = res.data.data.total;
      } catch (e) {
        this.loading = false;
      }
    },
    //地区筛选
    setCityValue(val) {
      this.form.trainC = val[0];
      this.form.trainT = val[1];
    },
    //培训筛选项
    selectList() {
      this.$api.selectListApi({dictCode: 'train_type'}).then(res => {
        if (res.data.success) {
          this.trainTab = res.data.data;
        }
      })
    },
    //残疾筛选项
    disabledType() {
      this.$api.selectListApi({dictCode: 'disabled_type'}).then(res => {
        if (res.data.success) {
          this.defect = res.data.data;
        }
      })
    },
    //分页
    handleCurrentChange(val) {
      this.page.pageNum = val;
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
    },
  },
  created() {
    this.location = localStorage.getItem("locationSite");
    if (this.location == "陕西省") {
      this.form.trainP = "p23";
    }else if(this.location == "湖南省"){
      this.form.trainP = "p22";
    }
    this.train();
    this.selectList();
    this.disabledType();
    //判断是否移动端
    this.$mobile('https://lzmz.cdpee.org.cn/#/pages/train/train');
  },
  mounted() {
    this.$emit('goRouter', '/training/')
  }
}
</script>

<style scoped lang="less">
@import "~assets/css/train.less";
/deep/ .provinceCity {
  .list {
    .active {
      padding:2px 15px;
      font-size: 12px !important;
      color: white !important;
      background: #00924C !important;
    }
  }
}
</style>
