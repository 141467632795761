<template>
    <div class="city">
        <ul class="list flex-wrap line_clamp1" :style="{ marginLeft: marginLeft }">
            <!--      <li-->
            <!--        :class="{ active: active == 0 }"-->
            <!--        @click="tabs(0, locationItem, location), locationCity(locationText)"-->
            <!--        v-if="location.length > 0 && isLocation"-->
            <!--      >-->
            <!--        {{ locationText }}-->
            <!--      </li>-->
            <li :class="{ active: active == 0 }" @click="tabs(0, '')">不限</li>
            <li
                    v-for="(n, i) in list"
                    :key="i"
                    :class="{ active: active == i + 1 }"
                    @click="tabs(i + 1, n.dictOutDTOS, n.itemValue)"
            >
                {{ n.itemText }}
            </li>
        </ul>
        <el-collapse-transition v-if="false">
            <div
                    class="child flex"
                    v-show="childList.length > 0"
                    :style="{ marginLeft: marginLeft }"
            >
                <div class="child_list flex-wrap">
                    <div
                            class="all"
                            :class="{ active: actives == 0 }"
                            @click="childSelect(0)"
                    >
                        不限
                    </div>
                    <ul v-for="(item, index) in childList" :key="index">
                        <li
                                :class="{ active: actives == index + 1 }"
                                @click="childSelect(index + 1, item.itemValue)"
                        >
                            {{ item.itemText }}
                        </li>
                    </ul>
                </div>
            </div>
        </el-collapse-transition>
    </div>
</template>

<script>
    export default {
        name: "selectCity",
        props: {
            marginLeft: "",
            level: "",
            reset: "",
            isLocation: {
                type: Boolean,
                default: true,
            },
        },
        data() {
            return {
                location: [],
                locationText: "",
                active: 0,
                actives: 0,
                list: [],
                locationItem: [],
                childList: [],
                citys: [],
                isShow: false,
            };
        },
        beforeDestroy() {
        },
        destroyed() {
            window.removeEventListener("beforeunload", this.updateHandler);
        },
        watch: {
            reset: {
                handler: function (val) {
                    localStorage.removeItem("location");
                    localStorage.removeItem("hotLocation");
                    this.tabs(1, "");
                },
            },
        },
        methods: {
            //清除location事件
            updateHandler() {
                localStorage.removeItem("location");
                localStorage.removeItem("hotLocation");
            },
            city() {
                this.$api.hunanCity().then((res) => {
                    this.list = res.data.data;
                });
            },
            tabs(i, child, val) {
                this.active = i;
                this.actives = 0;
                if (!child) {
                    this.childList = [];
                }
                if (val != undefined) {
                    this.citys[0] = val.toString();
                } else {
                    this.citys[0] = val;
                }
                if (this.citys.length > 1) {
                    this.citys.pop();
                }
                this.$emit("getVal", this.citys);
                if (this.level == 2 || !this.level) {
                    this.childTab(child);
                }
            },
            childTab(val) {
                if (val) {
                    this.childList = val;
                } else {
                    this.childList = [];
                }
            },
            childSelect(i, val) {
                this.actives = i;
                this.citys[1] = val;
                this.$emit("getVal", this.citys);
            },
            //  根据定位选择出现城市筛选
            locationCity(text) {
                let that = this;
                this.active = 0;
                this.location = [];
                this.childList = [];
                let city;
                if (text) {
                    city = text.replace("市", "");
                }
                this.$axios
                    .get("/api/app-jycy-sysdict/getThreeCityListByCityName", {
                        city: city,
                    })
                    .then((ref) => {
                        this.actives = 0;
                        this.location.push(city);
                        this.locationText = city;
                        ref.data.forEach((n, i) => {
                            that.childList.push({itemText: n.label, itemValue: n.value});
                        });
                    })
                    .catch((err) => {
                    });
            },
        },
        created() {
            window.addEventListener("beforeunload", this.updateHandler);
        },
        mounted() {
            this.city();

        },
    };
</script>

<style scoped lang="less">
    .city {
        flex: 1;
        position: relative;

        .list {
            /*height: 30px;*/

            li {
                height: 100%;
                padding: 0 10px;
                cursor: pointer;
                color: #666666;
                margin-right: 10px;
                margin-bottom: 10px;
            }

            li.active {
                font-size: 14px;
                color: #00924c;
                background: rgba(40, 164, 109, 0.12);
                border-radius: 5px;
                /*border-top-left-radius: 5px;*/
                /*border-top-right-radius: 5px;*/
            }

            /*li:first-child {*/
            /*  height: 24.6px;*/
            /*}*/
            /*li:first-child.active {*/
            /*  color: white;*/
            /*  background: #00924c;*/
            /*  padding: 0 15px;*/
            /*  border-radius: 5px;*/
            /*  font-size: 12px;*/
            /*}*/
        }

        .more {
            position: absolute;
            top: 1px;
            right: 0;
            color: #00924c;
        }

        .child {
            .name {
                flex: 1;
                color: #666666;
                padding: 20px 0;
            }

            .child_list {
                padding-top: 20px;
                padding-left: 15px;
                padding-bottom: 10px;
                margin-bottom: 10px;
                flex: 10;
                background: #f9f9f9;

                .all {
                    padding: 1.5px 15px;
                    margin-bottom: 10px;
                    cursor: pointer;
                    color: #666666;
                    margin-right: 10px;
                }

                .all.active {
                    font-size: 12px;
                    color: white;
                    background: #00924c;
                    border-radius: 5px;
                }

                ul {
                    li {
                        padding: 1.5px 15px;
                        margin-bottom: 10px;
                        cursor: pointer;
                        color: #666666;
                        margin-right: 10px;
                    }

                    li.active {
                        font-size: 12px;
                        color: white;
                        background: #00924c;
                        border-radius: 5px;
                    }
                }
            }
        }
    }
</style>
